/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
@media (min-width: 769px) {
  body.filter-active .plp-grid-banner .banner-text {
    display: none; } }

#product-search-results .plp-grid-banner {
  max-width: 100%;
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  padding: 0 16px; }
  @media (min-width: 769px) {
    #product-search-results .plp-grid-banner {
      -ms-flex-preferred-size: 66.66667%;
          flex-basis: 66.66667%; } }
  #product-search-results .plp-grid-banner .banner-tile-container {
    padding: 10px; }
    #product-search-results .plp-grid-banner .banner-tile-container h1,
    #product-search-results .plp-grid-banner .banner-tile-container h2 {
      margin-bottom: 16px;
      color: #004987;
      font-size: 2.25rem; }
    @media (min-width: 769px) {
      #product-search-results .plp-grid-banner .banner-tile-container h1 {
        font-size: 1.9rem;
        margin-bottom: 8px; } }
    @media (min-width: 1200px) {
      #product-search-results .plp-grid-banner .banner-tile-container h1 {
        font-size: 2.25rem;
        margin-bottom: 16px; } }
    #product-search-results .plp-grid-banner .banner-tile-container .banner-tile {
      padding: unset; }
      #product-search-results .plp-grid-banner .banner-tile-container .banner-tile.left .banner-text {
        text-align: left; }
      #product-search-results .plp-grid-banner .banner-tile-container .banner-tile.right .banner-text {
        text-align: left; }
        @media (min-width: 769px) {
          #product-search-results .plp-grid-banner .banner-tile-container .banner-tile.right .banner-text {
            margin-left: unset;
            margin-right: 5%;
            right: 0; } }
      #product-search-results .plp-grid-banner .banner-tile-container .banner-tile .banner-text {
        max-width: 100%;
        width: 100%;
        word-wrap: normal;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1; }
        @media (min-width: 769px) {
          #product-search-results .plp-grid-banner .banner-tile-container .banner-tile .banner-text {
            width: 33%;
            margin-left: 5%;
            position: absolute; } }
      @media (min-width: 769px) {
        #product-search-results .plp-grid-banner .banner-tile-container .banner-tile p {
          font-size: 1rem; } }
      @media (min-width: 1200px) {
        #product-search-results .plp-grid-banner .banner-tile-container .banner-tile p {
          font-size: 1.25rem; } }
    #product-search-results .plp-grid-banner .banner-tile-container .content-tile-figure {
      -ms-flex-direction: column;
          flex-direction: column; }
      #product-search-results .plp-grid-banner .banner-tile-container .content-tile-figure img {
        display: none;
        width: 100%; }
        @media (min-width: 769px) {
          #product-search-results .plp-grid-banner .banner-tile-container .content-tile-figure img {
            display: block;
            padding: 0 10px; } }

#product-search-results .plp-grid-content .content-tile-body {
  display: block;
  width: 100%; }

#product-search-results .plp-grid-content .content-tile-text {
  max-width: 100%;
  width: 100%;
  word-wrap: normal;
  position: relative;
  color: #004987;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%); }
  @media (min-width: 769px) {
    #product-search-results .plp-grid-content .content-tile-text {
      width: 33%;
      margin-left: 10%;
      position: absolute; } }
  @media (min-width: 1024px) {
    #product-search-results .plp-grid-content .content-tile-text {
      width: 33%; } }
